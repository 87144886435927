import getBudgetRangeTags from "./getBudgetRangeTags.js";

export function getBudgetRange(price) {
  const determinedRangeName = findRange(price);

  const rangeTag = getBudgetRangeTags().filter(
    (tag) => tag.name === determinedRangeName
  )[0];

  return rangeTag ? rangeTag : null;
}

function findRange(price) {
  const keys = Object.keys(ranges);

  for (let key of keys) {
    const range = ranges[key];
    if (price >= range.lower && price < range.upper) return key;
  }
  return null;
}

export const ranges = {
  Klein: {
    lower: 1000,
    upper: 5000,
  },
  Mittel: {
    lower: 5000,
    upper: 10000,
  },
  Gross: {
    lower: 10000,
    upper: 50000,
  },
  "Sehr Gross": {
    lower: 50000,
    upper: Number.MAX_VALUE,
  },
};
