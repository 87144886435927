<template>
  <v-card data-testId="EmailData">
    <v-card-title>Email</v-card-title>
    <v-card-text>
      <v-form
        :disabled="locked"
        ref="form"
        v-model="valid"
        :readonly="!editing"
        @submit.prevent="saveData"
      >
        <v-row>
          <v-col cols="3">
            <v-subheader>Email</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="email" :rules="emailRule"></v-text-field>
          </v-col>
        </v-row>
        <v-btn v-if="editing" @click="setEditMode(false)">Abbrechen</v-btn>
        <v-btn v-else :disabled="locked" @click="setEditMode(true)"
          >Bearbeiten
        </v-btn>
        <v-btn color="secondary" type="submit" :disabled="!editing"
          >Speichern
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Emaildata",
  props: ["user", "locked"],

  data() {
    return {
      editing: false,
      valid: true,
      email: this.user.email,
      emailRule: [(v) => /.+@.+\..+/.test(v) || "Bitte gültige E-Mail angeben"],
    };
  },

  methods: {
    saveData() {
      this.$refs.form.validate();
      if (this.valid) {
        const updatedUser = this.setPropertyValues(this.user);
        this.updateUserEmail(updatedUser);
        this.setEditMode(false);
      }
    },
    setEditMode(edit) {
      if (edit) {
        this.$emit("editing", "emailData");
        this.editing = true;
      } else {
        this.$emit("editing", "");
        this.editing = false;
        this.resetForm();
      }
    },

    setPropertyValues(user) {
      user.email = this.email;
      return user;
    },

    resetForm() {
      this.email = this.user.email;
    },

    ...mapActions(["updateUserEmail"]),
  },
};
</script>

<style>
.v-btn {
  margin-right: 8px;
}
</style>
