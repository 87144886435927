<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-navigation-drawer width="auto" permanent floating>
            <v-card-title>Projekte filtern</v-card-title>
            <v-divider></v-divider>

            <!-- Type -->
            <v-select
              v-model="type"
              :items="types"
              item-text="translated"
              clearable
              filled
              dense
              label="Kategorie"
              return-object
              @change="filterProjects()"
              @click:clear="clearFromLocalStorage('type', type)"
            ></v-select>

            <!-- Tags -->
            <v-select
              v-model="budgetRange"
              :items="getGeneralInfoTypeTags('BudgetRanges')"
              item-text="detail"
              clearable
              filled
              dense
              label="Budget"
              return-object
              @change="filterProjects()"
            ></v-select>
            <v-select
              v-model="companySize"
              :items="getGeneralInfoTypeTags('CompanySize')"
              item-text="detail"
              clearable
              filled
              dense
              label="Unternehmensgrösse"
              return-object
              @change="filterProjects()"
            ></v-select>
            <v-select
              v-model="skillLevel"
              :items="getGeneralInfoTypeTags('SkillLevel')"
              item-text="name"
              item-value="detail"
              clearable
              filled
              dense
              label="Könnerstufe"
              return-object
              @change="filterProjects()"
            ></v-select>
          </v-navigation-drawer>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <v-select
          v-model="selectedOrderBy"
          :items="sortCriteria"
          label="Sortieren nach"
          @change="
            sortProjects({
              selectedOrderBy: selectedOrderBy,
              orderByMap: orderByMap,
            })
          "
        ></v-select>
        <div class="text-h7 font-italic">
          Resultate: {{ getOpenProjects ? getOpenProjects.length : 0 }}
        </div>

        <!-- display projects -->
        <div v-for="project in paginatedProjects" :key="project.id">
          <v-hover v-slot="{ hover }">
            <v-card
              class="project"
              color="tertiary40"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              :to="{
                name: 'ProjectDetail',
                params: { id: project.id },
              }"
              exact
            >
              <v-card-title class="d-flex flex-row justify-space-between"
                ><span>{{ project.name }}</span>
                <span>
                  <v-chip class="project-attribute project-price justify-center"
                    ><span>{{ project.provisionalPrice }} CHF</span></v-chip
                  >
                  <v-chip
                    class="
                      project-attribute project-plannedstart
                      justify-center
                    "
                    ><span>{{ formatDate(project.plannedStart) }}</span></v-chip
                  >
                </span>
              </v-card-title>
              <v-card-text>{{ truncatedText(project.detail) }}</v-card-text>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      color="secondary"
      :length="numberOfPages"
      @input="jump()"
    ></v-pagination>
  </v-container>
</template>

<script>
import { translations } from "@/shared/translations";
import { datetimeStringToSwissDateString } from "@/shared/formatDate";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Project",
  data() {
    return {
      sortCriteria: [
        "Neueste",
        "Älteste",
        "Projektstart aufsteigend",
        "Projektstart absteigend",
      ],
      type: this.readTypeFromLocalStorage(),
      companySize: this.readTagFromLocalStorage("companySize"),
      skillLevel: this.readTagFromLocalStorage("skillLevel"),
      budgetRange: this.readTagFromLocalStorage("budgetRange"),
      selectedOrderBy: "Neueste",
      orderByMap: {
        Neueste: { orderBy: "id", Asc: false },
        Älteste: { orderBy: "id", Asc: true },
        "Projektstart aufsteigend": { orderBy: "plannedStart", Asc: true },
        "Projektstart absteigend": { orderBy: "plannedStart", Asc: false },
      },
      page: 1,
      offset: 0,
      projectsPerPage: 4,
    };
  },
  computed: {
    numberOfPages() {
      return this.getOpenProjects.length
        ? Math.ceil(this.getOpenProjects.length / this.projectsPerPage)
        : 1;
    },

    paginatedProjects() {
      return this.getOpenProjects.slice(
        this.offset,
        this.offset + this.projectsPerPage
      );
    },

    ...mapState({ types: (state) => state.wizard.types }),
    ...mapGetters(["getGeneralInfoTypeTags", "getOpenProjects"]),
  },

  methods: {
    translate(word) {
      return translations(word);
    },

    formatDate(datetimeString) {
      return datetimeStringToSwissDateString(datetimeString);
    },

    truncatedText(input) {
      return input.length > 300 ? `${input.substring(0, 300)}...` : input;
    },

    async filterProjects() {
      let filterCriteria = { typeId: [], tagIds: [] };
      let tags = {};

      if (this.type !== null) {
        filterCriteria.typeId.push(this.type.id);
        this.writeToLocalStorage("type", this.type);
      }

      if (this.budgetRange !== null) {
        filterCriteria.tagIds.push(this.budgetRange.id);
        tags["budgetRange"] = this.budgetRange;
      }

      if (this.companySize !== null) {
        filterCriteria.tagIds.push(this.companySize.id);
        tags["companySize"] = this.companySize;
      }

      if (this.skillLevel !== null) {
        filterCriteria.tagIds.push(this.skillLevel.id);
        tags["skillLevel"] = this.skillLevel;
      }

      this.writeToLocalStorage("tags", tags);

      await this.fetchProjects(filterCriteria);
    },

    readTypeFromLocalStorage() {
      const type = JSON.parse(localStorage.getItem("type"));
      return type ? type : null;
    },

    readAllTagsFromLocalStorage() {
      const tags = JSON.parse(localStorage.getItem("tags"));
      return tags ? tags : null;
    },

    readTagFromLocalStorage(topicName) {
      const tags = this.readAllTagsFromLocalStorage();

      if (tags && tags[topicName]) {
        return tags[topicName];
      }
      return null;
    },

    writeToLocalStorage(key, item) {
      localStorage.setItem(key, JSON.stringify(item));
    },

    clearFromLocalStorage(key) {
      localStorage.removeItem(key);
    },

    jump() {
      this.offset = this.projectsPerPage * (this.page - 1);
    },

    ...mapActions(["fetchProjects", "sortProjects"]),
  },

  async created() {
    const type = this.readTypeFromLocalStorage();
    const tags = this.readAllTagsFromLocalStorage();
    const tagIds = tags ? Object.keys(tags).map((key) => tags[key].id) : [];

    const filterCriteria = {
      typeId: type ? [type.id] : [],
      tagIds: tagIds,
    };

    await this.fetchProjects(filterCriteria);

    this.sortProjects({
      selectedOrderBy: this.selectedOrderBy,
      orderByMap: this.orderByMap,
    });
  },
};
</script>

<style scoped>
.container {
  padding-right: 25px;
  margin-left: 0;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
  margin-bottom: 25px;
}

.v-card:not(.on-hover) {
  opacity: 0.8;
}

.project-attribute {
  margin-left: 0.5em;
}

.project-price {
  width: 7em;
}

.project-plannedstart {
  width: 11em;
}
</style>
