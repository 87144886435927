export function formatDate(date) {
  if (!date) return null;

  let parsedDate = new Date(Date.parse(date));

  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  return parsedDate.toLocaleDateString("de-DE", dateOptions);
}

export function formatDateTime(date) {
  if (!date) return null;

  let parsedDate = new Date(Date.parse(date));

  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  return `${parsedDate.toLocaleDateString(
    "de-DE",
    dateOptions
  )} um ${parsedDate.toLocaleTimeString("de-DE", timeOptions)}`;
}

export function datetimeStringToSwissDateString(datetimeString) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  let parsedDate = new Date(Date.parse(datetimeString));
  return parsedDate.toLocaleDateString("de-DE", options);
}
