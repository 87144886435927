export const state = {
  loading: 0,
};

export const mutations = {
  SET_LOADING(state) {
    state.loading++;
  },
  REMOVE_LOADING(state) {
    if (state.loading > 0) state.loading--;
  },
};

export const actions = {
  newRequest({ commit }) {
    commit("SET_LOADING");
  },
  endRequest({ commit }) {
    commit("REMOVE_LOADING");
  },
};

export const getters = {
  isLoading: (state) => {
    return state.loading > 0;
  },
};
