<template>
  <v-row justify="center" data-testid="applicationForm">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" v-bind="attrs" v-on="on"> Bewerben </v-btn>
      </template>
      <v-card class="applicationForm">
        <v-card-title>
          <span class="headline">Bewerbung erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submitData" v-model="valid">
            <v-text-field
              type="number"
              v-model="budgetOffer"
              :rules="budgetOfferRules"
              prepend-icon="mdi-cash"
              label="Angebotspreis"
              required
            ></v-text-field>

            <v-textarea
              v-model="motivationText"
              :rules="motivationTextRules"
              prepend-icon="mdi-email"
              label="Motivationsschreiben"
              required
            ></v-textarea>

            <v-flex class="d-flex justify-end">
              <v-btn text @click="dialog = false"> Abbrechen </v-btn>
              <v-btn text type="submit"> Absenden </v-btn>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ranges } from "@/shared/budgetRanges.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["project", "projectTags"],
  data() {
    return {
      dialog: false,
      valid: true,
      budgetOffer: null,
      budgetOfferRules: [
        (v) => !!v || "Bitte einen Angebotspreis angeben",
        (v) => (v && v > 0) || "Nur positive Werte erlaubt",
        (v) =>
          (v && v >= this.budgetRange.lower / 2) || "Angebotspreis zu tief",
        (v) =>
          (v && v <= this.budgetRange.upper * 2) || "Angebotspreis zu hoch",
      ],
      motivationText: "",
      motivationTextRules: [
        (v) => !!v || "Bitte ein Motivationsschreiben verfassen",
        (v) =>
          v.length <= 5000 || "Motivationsschreiben max. 5000 Zeichen lang",
      ],
    };
  },
  computed: {
    budgetRange() {
      const result = ranges[this.budgetRangeName];
      return result;
    },

    budgetRangeName() {
      return this.projectTags["BudgetRanges"][0].name;
    },

    ...mapGetters(["getUserId"]),
  },

  methods: {
    async submitData() {
      this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          userId: this.getUserId,
          projectId: this.project.id,
          budgetOffer: this.budgetOffer,
          motivation: this.motivationText,
        };

        const status = await this.createApplication(payload);

        if (status && status >= 200 && status < 400) {
          this.setSelectedProject(this.project);
          this.$router.push({ name: "Dashboard" });
          this.$refs.form.reset();
        }
      }
    },

    ...mapActions(["createApplication", "setSelectedProject"]),
  },
};
</script>

<style scoped>
.applicationForm {
  margin-top: 0;
}
</style>
