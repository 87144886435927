<template>
  <v-container fluid class="formContainer">
    <div class="text-h4">Projekt erstellen</div>
    <v-row>
      <v-col cols="12" md="7" lg="7" xl="7">
        <v-form
          data-testid="createProject"
          ref="form"
          @submit.prevent="submitData"
          v-model="valid"
        >
          <v-alert v-if="showError" type="warning" outlined icon="mdi-alert">
            Keine Projektoptionen ausgewählt. Bitte Wizard neu starten
            <router-link
              class="link"
              :to="{
                name: 'ChooseType',
              }"
              exact
              >zum Wizard
            </router-link>
          </v-alert>

          <v-text-field
            v-model="name"
            :rules="nameRules"
            prepend-icon="mdi-label-outline"
            label="Name"
            required
          ></v-text-field>

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="plannedStartToDisplay"
                label="Startdatum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :min="new Date().toISOString()"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            type="number"
            v-model="provisionalPrice"
            :rules="priceRules"
            prepend-icon="mdi-cash-100"
            label="Richtpreis CHF"
            required
          ></v-text-field>
          <v-text-field
            type="number"
            prepend-icon="mdi-bank-outline"
            label="MWST"
            disabled
            readonly
            :value="vat"
          ></v-text-field>

          <v-text-field
            type="number"
            prepend-icon="mdi-cash"
            label="Gebühr"
            disabled
            readonly
            :value="commission"
          ></v-text-field>

          <v-text-field
            type="number"
            prepend-icon="mdi-cash-register"
            label="Total CHF"
            readonly
            :value="total"
          ></v-text-field>

          <v-textarea
            v-model="detail"
            :rules="detailRules"
            filled
            prepend-icon="mdi-account-details"
            color="teal"
          >
            <template v-slot:label>
              <div>Detailberschrieb zu Deinem Projekt</div>
            </template>
          </v-textarea>
          <v-flex class="d-flex justify-space-between">
            <v-btn class="mr-4" @click="$router.go(-1)">zurück</v-btn>
            <v-btn color="secondary" class="mr-4" type="submit"
              >Projekt ausschreiben
            </v-btn>
          </v-flex>
        </v-form>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5">
        <ShowTopicsAndTags :projectTags="selectedTags" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShowTopicsAndTags from "@/components/projectWizard/ShowTopicsAndTags.vue";
import { formatDate } from "@/shared/formatDate.js";
import { getBudgetRange } from "@/shared/budgetRanges.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: { ShowTopicsAndTags },

  data() {
    return {
      showError: false,
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Bitte Projektnamen angeben",
        (v) => (v && v.length) <= 100 || "Namen max. 100 Zeichen lang",
      ],
      detail: "",
      detailRules: [
        (v) => !!v || "Bitte eine Beschreibung eintragen",
        (v) =>
          (v && v.length > 50) ||
          "Beschreibung sollte min. 50 Zeichen lang sein",
        (v) => v.length <= 5000 || "Beschreibung max. 5000 Zeichen lang",
      ],
      provisionalPrice: null,
      priceRules: [
        (v) => !!v || "Bitte einen Richtpreis angeben",
        (v) => (v && v > 0) || "Nur positive Werte erlaubt",
        (v) => (v && v >= 1000) || "Projektpreis mindestens CHF 1'000.-",
      ],
      VAT: 0.077,
      PROVISION: 0.03,
      date: new Date().toISOString().substr(0, 10),
      plannedStartToDisplay: formatDate(new Date().toISOString().substr(0, 10)),
      menu: false,
      dateRules: [(v) => !!v || "Bitte Startdatum angeben"],
    };
  },

  computed: {
    computedDateFormatted() {
      return formatDate(this.date);
    },

    vat() {
      return this.calculate(this.provisionalPrice, this.VAT);
    },

    commission() {
      return this.calculate(this.provisionalPrice, this.PROVISION);
    },

    total() {
      return this.calculate(
        parseInt(this.provisionalPrice) +
          parseInt(this.vat) +
          parseInt(this.commission),
        1
      );
    },

    ...mapState({
      selectedType: (state) => state.wizard.selectedType,
      selectedTags: (state) => state.wizard.selectedTags,
    }),

    ...mapGetters(["getUserId"]),
  },

  watch: {
    date() {
      this.plannedStartToDisplay = formatDate(this.date);
    },

    provisionalPrice(val) {
      if (val < 1000) return;
      const newTag = {
        topicName: "BudgetRanges",
        tag: getBudgetRange(this.provisionalPrice),
      };

      this.replaceSelectedTag(newTag);
    },
  },

  methods: {
    typeAndTagsSelected() {
      return Object.keys(this.selectedTags).length > 0 && this.selectedType;
    },

    calculate(amount, percentage) {
      return amount > 0
        ? (Math.round(amount * percentage * 100) / 100).toFixed(2)
        : "";
    },

    async submitData() {
      this.$refs.form.validate();

      if (this.valid) {
        if (this.typeAndTagsSelected()) {
          const payload = {
            userId: this.getUserId,
            name: this.name,
            detail: this.detail,
            plannedStart: new Date(this.date).toISOString(),
            provisionalPrice: this.provisionalPrice,
            status: 0,
            balance: 0,
            typeId: this.selectedType.id,
            tagIds: this.getTagIds(),
          };

          const status = await this.createProject(payload);

          if (status && status >= 201 && status < 400) {
            this.clearSelectedTags();
            this.clearSelectedType();
            this.$router.push({ name: "Project" });
            this.$refs.form.reset();
          }
        } else {
          this.showError = true;
        }
      }
    },

    getTagIds() {
      let ids = [];
      for (let topic in this.selectedTags) {
        const tags = this.selectedTags[topic];
        ids.push(tags.map((tag) => tag.id));
      }
      return [].concat.apply([], ids);
    },

    ...mapActions([
      "createProject",
      "clearSelectedTags",
      "clearSelectedType",
      "replaceSelectedTag",
    ]),
  },
};
</script>

<style scoped>
.v-form {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.v-btn {
  margin-top: 25px;
  margin-bottom: 50px;
}

.link {
  text-decoration: none;
}

.v-chip {
  display: inline-block;
}

.formContainer {
  padding-left: 5%;
  padding-right: 5%;
}
</style>
