<template>
  <v-container>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          :color="setHeaderColor(application.applicationStatus)"
          ><span>
            {{ translate(application.applicationStatus) }}
          </span>
          <span class="d-flex justify-end">
            <v-chip>
              {{ application.user.firstname + " " + application.user.lastname }}
            </v-chip>
            <v-chip> {{ application.budgetOffer }} CHF </v-chip>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-text-field
              type="text"
              prepend-icon="mdi-account"
              label="Anbieter"
              :value="
                application.user.firstname + ' ' + application.user.lastname
              "
              readonly
            ></v-text-field>

            <v-text-field
              type="number"
              prepend-icon="mdi-cash"
              label="Angebotspreis"
              :value="application.budgetOffer"
              readonly
            ></v-text-field>

            <v-textarea
              type="text"
              prepend-icon="mdi-email"
              label="Motivation"
              readonly
              rows="1"
              auto-grow
              :value="application.motivation"
            ></v-textarea>

            <div v-if="application.applicationStatus === 'Open'">
              <ActionDialog
                v-if="userRole === 'Customer'"
                action="Annehmen"
                actionColor="success"
                actionDescription="Möchtest du diese Bewerbung annehmen?"
                actionEvent="accept-application"
                @accept-application="acceptApplicationAndRefresh(application)"
              />

              <ActionDialog
                v-if="userRole === 'Customer'"
                action="Ablehnen"
                actionColor="error"
                actionDescription="Möchtest du diese Bewerbung ablehnen?"
                actionEvent="reject-application"
                @reject-application="
                  rejectApplicationAndRefresh(application.id)
                "
              />

              <ActionDialog
                v-if="userRole === 'NotDefined'"
                action="Zurückziehen"
                actionColor="error"
                actionDescription="Möchtest du diese Bewerbung zurückziehen?"
                actionEvent="withdraw-application"
                @withdraw-application="
                  withdrawApplicationAndRefresh(application.id)
                "
              />
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ActionDialog from "@/components/application/ActionDialog.vue";
import { translations } from "@/shared/translations";
import { mapActions, mapState } from "vuex";

export default {
  name: "ShowApplications",
  props: ["application", "userRole", "userId"],
  components: {
    ActionDialog,
  },

  data() {
    return {
      colorMap: {
        Open: "warning10",
        Accepted: "success20",
        Rejected: "error10",
        Withdrawn: "error10",
      },
    };
  },
  computed: {
    ...mapState({ selectedProject: (state) => state.project.selectedProject }),
  },

  methods: {
    setHeaderColor(status) {
      return this.colorMap[status];
    },

    translate(word) {
      return translations(word);
    },

    async acceptApplicationAndRefresh(application) {
      const applicationPayload = {
        applicationId: application.id,
        projectId: this.selectedProject.id,
      };
      await this.acceptApplication(applicationPayload);

      const chatPayload = {
        projectId: this.selectedProject.id,
        senderId: this.userId,
        receiverId: application.userId,
        textMessage:
          "Automatische Nachricht: Willkommen in meinem Projekt! Ich freue mich auf unsere Zusammenarbeit.",
      };

      const projectPayload = {
        projectId: this.selectedProject.id,
        contractorId: application.userId,
        agreedPrice: application.budgetOffer,
      };

      const status = await this.initializeProject(projectPayload);
      if (status && status >= 200 && status < 400) {
        await this.createMessage(chatPayload);
        this.$router.go(0);
      }
    },

    async rejectApplicationAndRefresh(applicationId) {
      await this.rejectApplication(applicationId);
      this.$router.go(0);
    },

    async withdrawApplicationAndRefresh(applicationId) {
      await this.withdrawApplication(applicationId);
      this.$router.go(0);
    },

    ...mapActions([
      "acceptApplication",
      "initializeProject",
      "rejectApplication",
      "withdrawApplication",
      "createMessage",
    ]),
  },
};
</script>
<style scoped>
.v-chip {
  margin-right: 1rem;
}
</style>
