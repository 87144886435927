import store from "@/store/store.js";

export const authenticationGuard = (to, from, next) => {
  const authService = store.getters.loggedIn;

  if (authService) {
    return next();
  }

  next({ name: "Login" });
};
