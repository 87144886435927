<template>
  <v-container fluid>
    <div class="text-h4">Seite nicht gefunden</div>
    <v-card>
      <v-img src="@/shared/assets/404_NotFound.jpg"></v-img>
      <v-card-text
        >zurück zu den
        <router-link :to="{ name: 'Project' }">Projekten</router-link>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.v-card {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.v-image {
  margin-left: auto;
  margin-right: auto;
  height: 200px;
}
</style>
