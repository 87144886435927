<template>
  <v-card data-testid="personalData">
    <v-card-title>Personalien</v-card-title>
    <v-card-text>
      <v-form
        :disabled="locked"
        ref="form"
        v-model="valid"
        :readonly="!editing"
        @submit.prevent="saveData"
      >
        <v-row>
          <v-col cols="3">
            <v-subheader>Name</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-text-field :value="user.firstname" disabled></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field :value="user.lastname" disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-subheader>Adresse</v-subheader>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="street"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="houseNumber"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-subheader>PLZ / Ort</v-subheader>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="number"
              v-model="zipCode"
              :rules="zipCodeRule"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="town"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-subheader>Land</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="country"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-subheader>Telefon</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="phone" :rules="phoneRule"></v-text-field>
          </v-col>
        </v-row>
        <v-btn v-if="editing" @click="setEditMode(false)">Abbrechen </v-btn>
        <v-btn v-else :disabled="locked" @click="setEditMode(true)"
          >Bearbeiten
        </v-btn>
        <v-btn color="secondary" type="submit" :disabled="!editing"
          >Speichern
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PersonalData",
  props: ["user", "locked"],

  data() {
    return {
      editing: false,
      valid: true,
      street: this.user.street,
      houseNumber: this.user.houseNumber,
      zipCode: this.user.zipCode,
      town: this.user.town,
      country: this.user.country,
      phone: this.user.phone,
      phoneRule: [
        (v) =>
          !v ||
          /^(?=.*\d)[\d ]+$/.test(v) ||
          "Bitte gültige Telefonnummer angeben",
      ],
      zipCodeRule: [
        (v) => !v || v.length >= 4 || "Bitte gültige Postleitzahl eingeben",
      ],
    };
  },

  methods: {
    saveData() {
      this.$refs.form.validate();
      if (this.valid) {
        const updatedUser = this.setPropertyValues(this.user);
        this.editing = false;
        this.updateUser(updatedUser);
        this.setEditMode(false);
      }
    },

    setEditMode(edit) {
      if (edit) {
        this.$emit("editing", "personalData");
        this.editing = true;
      } else {
        this.$emit("editing", "");
        this.editing = false;
        this.resetForm();
      }
    },

    setPropertyValues(user) {
      user.street = this.street;
      user.houseNumber = this.houseNumber;
      user.zipCode = this.zipCode;
      user.town = this.town;
      user.country = this.country;
      user.phone = this.phone;
      return user;
    },

    resetForm() {
      this.street = this.user.street;
      this.houseNumber = this.user.houseNumber;
      this.zipCode = this.user.zipCode;
      this.town = this.user.town;
      this.country = this.user.country;
      this.phone = this.user.phone;
    },

    ...mapActions(["updateUser"]),
  },
};
</script>

<style scoped>
.v-btn {
  margin-right: 8px;
}
</style>
