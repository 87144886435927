<template>
  <v-dialog
    data-testId="actionDialog"
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn :color="actionColor" v-on="on"> {{ action }} </v-btn>
    </template>

    <v-card class="dialog">
      <v-card-title class="headline"> {{ actionDescription }} </v-card-title>
      <v-card-text
        >Diese Aktion ist definitiv und kann nicht rückgängig gemacht werden.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          zurück
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="
            $emit(actionEvent);
            dialog = false;
          "
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionDialog",
  props: ["actionDescription", "actionEvent", "actionColor", "action"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.dialog {
  margin-top: 0px;
}

.v-btn {
  margin-right: 10px;
}
</style>
