<template>
  <v-container data-testid="userOverview">
    <PersonalData
      :user="user"
      :locked="lockPersonalData"
      @editing="lockComponents"
    />

    <EmailData :user="user" :locked="lockEmailData" @editing="lockComponents" />

    <TitleAndBio
      :user="user"
      :locked="lockTitleAndBio"
      @editing="lockComponents"
    />
  </v-container>
</template>

<script>
import PersonalData from "@/components/user/PersonalData.vue";
import EmailData from "@/components/user/EmailData.vue";
import TitleAndBio from "@/components/user/TitleAndBio.vue";
import { mapState } from "vuex";

export default {
  name: "UserOverview",
  components: { PersonalData, EmailData, TitleAndBio },

  data() {
    return {
      lockPersonalData: false,
      lockEmailData: false,
      lockTitleAndBio: false,
    };
  },

  computed: {
    ...mapState({ user: (state) => state.user.user }),
  },

  methods: {
    lockComponents(componentName) {
      switch (componentName) {
        case "personalData":
          this.lockEmailData = true;
          this.lockTitleAndBio = true;
          break;

        case "emailData":
          this.lockPersonalData = true;
          this.lockTitleAndBio = true;
          break;

        case "titleAndBio":
          this.lockPersonalData = true;
          this.lockEmailData = true;
          break;

        default:
          this.lockPersonalData = false;
          this.lockTitleAndBio = false;
          this.lockEmailData = false;
          break;
      }
    },
  },
};
</script>
