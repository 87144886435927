<template>
  <v-container fluid>
    <div class="text-h4">Projekt ausschreiben</div>
    <v-container>
      <v-row>
        <v-spacer d-none d-lg-block></v-spacer>
        <v-col
          v-for="(type, index) in types"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          <v-card
            data-testId="chooseType"
            v-if="!type.isGeneralInfo"
            :to="{
              name: 'ProjectWizard',
              params: { type: type.name },
            }"
            class="mb-12"
            color="#FFF"
            height="300"
          >
            <v-img
              max-height="75%"
              :src="require('@/shared/assets/' + type.name + '.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title>{{ translate(type.name) }}</v-card-title>
          </v-card>
        </v-col>
        <v-spacer d-none d-lg-block></v-spacer>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { translations } from "@/shared/translations";
import { mapActions, mapState } from "vuex";

export default {
  name: "ChooseType",

  computed: {
    ...mapState({
      types: (state) => state.wizard.types,
    }),
  },

  methods: {
    translate: translations,
    ...mapActions(["clearSelectedType", "clearSelectedTags"]),
  },

  created() {
    this.clearSelectedType();
    this.clearSelectedTags();
  },
};
</script>
