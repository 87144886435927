<template>
  <v-container>
    <v-row id="chat">
      <v-container v-for="(message, index) in messages" :key="index">
        <v-row v-if="showMessage(message, partnerChatUser)">
          <v-col cols="8">
            <div class="message partnerUserMessage">
              <p>{{ message.textMessage }}</p>
              <span class="timestamp"
                >{{
                  partnerChatUser.firstname +
                  " am " +
                  showDate(message.createdAt)
                }}
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="showMessage(message, currentChatUser)">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <div class="message currentUserMessage">
              <p>{{ message.textMessage }}</p>
              <span class="timestamp"
                >{{
                  currentChatUser.firstname +
                  " am " +
                  showDate(message.createdAt)
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="controls">
      <v-col cols="8">
        <v-text-field
          :disabled="selectedProject.status === 'Closed'"
          flat
          solo
          dense
          v-model="inputMessage"
          label="Eine Nachricht schreiben..."
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-btn
          :disabled="selectedProject.status === 'Closed' || !inputMessage"
          @click="
            submitMessage({
              projectId: selectedProject.id,
              senderId: currentChatUser.id,
              receiverId: partnerChatUser.id,
              textMessage: inputMessage,
            })
          "
          elevation="4"
          rounded
          >Senden
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { formatDateTime } from "@/shared/formatDate.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "Chat",
  props: ["currentChatUser", "partnerChatUser"],
  data() {
    return {
      inputMessage: null,
    };
  },

  computed: {
    ...mapState({
      messages: (state) => state.chat.messages,
      selectedProject: (state) => state.project.selectedProject,
    }),
  },

  methods: {
    showMessage(message, user) {
      return user && message.senderId === user.id ? true : false;
    },

    showDate(date) {
      const convertedDate = new Date(date);

      return formatDateTime(convertedDate);
    },

    scrollChatToBottom() {
      let element = document.getElementById("chat");
      if (element) element.scrollTop = element.scrollHeight;
    },

    submitMessage(payload) {
      this.createMessage(payload);
      this.inputMessage = "";
    },

    ...mapActions(["createMessage"]),
  },

  updated() {
    this.scrollChatToBottom();
  },
};
</script>
<style scoped>
.row {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.controls {
  border-top: 1px lightgray solid;
  height: 64px;
}

#chat {
  padding: 0;
  max-height: 300px;
  overflow-y: scroll;
}

.message {
  border: 2px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.currentUserMessage {
  background-color: #f1f2f3;
}

.partnerUserMessage {
  background-color: #e4f0f8;
}

.timestamp {
  font-size: small;
}

.v-btn {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
