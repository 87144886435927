import ChooseType from "@/views/wizard/project/ChooseType.vue";
import ProjectWizard from "@/views/wizard/project/ProjectWizard.vue";
import Project from "@/views/Project.vue";
import Dashboard from "@/views/Dashboard.vue";
import ProjectForm from "@/views/wizard/project/ProjectForm.vue";
import ProjectDetail from "@/views/ProjectDetail.vue";
import Login from "@/views/Login.vue";
import Registration from "@/views/Registration.vue";
import NotFound from "@/views/NotFound.vue";
import UserOverview from "@/views/UserOverview";
import { authenticationGuard } from "@/shared/auth/authGuard.js";

export const routes = [
  {
    path: "/",
    name: "Project",
    component: Project,
  },
  {
    path: "/chooseType",
    name: "ChooseType",
    component: ChooseType,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/project-wizard/:type",
    name: "ProjectWizard",
    component: ProjectWizard,
    props: true,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/project/create",
    name: "ProjectForm",
    component: ProjectForm,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/project/detail/:id",
    name: "ProjectDetail",
    component: ProjectDetail,
    props: true,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/user/overview",
    name: "UserOverview",
    component: UserOverview,
    beforeEnter: authenticationGuard,
  },
  { path: "*", name: "NotFound", component: NotFound },
];
