<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      right
      style="position: fixed; top: 0; right: 0; overflow-y: scroll"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item :to="{ name: 'Project' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Projekte</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'ChooseType' }">
            <v-list-item-icon>
              <v-icon>mdi-folder-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Neues Projekt</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'Dashboard' }">
            <v-list-item-icon>
              <v-icon>mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="loggedIn ? { name: 'UserOverview' } : { name: 'Login' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>User</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="loggedIn" @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Hamburger",
  props: ["loggedIn"],
  data() {
    return {
      drawer: false,
      group: null,
    };
  },

  methods: {
    setDrawer(value) {
      this.drawer = value;
    },

    ...mapActions(["logout"]),
  },
};
</script>

<style></style>
