import { apiClientInstance } from "./apiClientInstance";

export const getProjectApplications = async (projectId) => {
  const applications = await apiClientInstance.get(
    "/application?projectId=" + projectId
  );

  return applications.data;
};

export const getUserApplications = async (projectId, userId) => {
  const applications = await apiClientInstance.get(
    "/application?projectId=" + projectId + "&userId=" + userId
  );
  return applications.data;
};

export const getApplicantUser = async (userId) => {
  const user = await apiClientInstance.get("/userInfo/" + userId);
  return user.data;
};

export const createApplication = async (payload) => {
  return await apiClientInstance.post("/application", payload);
};

export const acceptApplication = async (payload) => {
  const { applicationId, projectId } = payload;

  return await apiClientInstance.patch("/initialization/" + applicationId, {
    projectId: projectId,
  });
};

export const applyApplicationStatus = async (
  applicationId,
  applicationStatus
) => {
  return await apiClientInstance.patch("/application/" + applicationId, {
    applicationStatus: applicationStatus,
  });
};
