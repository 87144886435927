<template>
  <v-list-group :value="false">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          <v-avatar color="tertiary40" size="30">
            {{ projects.length }}
          </v-avatar>
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item
      v-for="(project, i) in projects"
      :key="i"
      link
      @click="viewProjectDetail(project)"
    >
      <v-list-item-title
        >{{ project.name }}
        <span v-if="project.status === 'Closed'"> (Abgeschlossen)</span>
      </v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: "ProjectOverciew",
  props: ["projects", "title"],

  methods: {
    viewProjectDetail(project) {
      this.$emit("clicked-on-project", project.id);
    },
  },
};
</script>

<style scoped>
.v-list-item {
  padding-left: 72px;
}
</style>
