<template>
  <v-container data-testId="dashboard" fluid>
    <div class="text-h4">Dashboard</div>
    <v-row>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-card>
          <v-toolbar color="tertiary20" flat>
            <v-toolbar-title>Projektübersicht</v-toolbar-title>
          </v-toolbar>

          <v-card-subtitle v-if="!appliedAndUserProjects.length"
            >Keine Projekte vorhanden
          </v-card-subtitle>

          <v-list v-else>
            <!-- applied -->
            <ProjectOverview
              v-if="hasEntries(roleNotDefinedProjects)"
              :projects="roleNotDefinedProjects"
              title="Beworben"
              @clicked-on-project="setProjectAndGetMessages"
            />

            <!-- customer -->
            <ProjectOverview
              v-if="hasEntries(customerProjects)"
              :projects="customerProjects"
              title="Erstellte Projekte"
              @clicked-on-project="setProjectAndGetMessages"
            />

            <!-- contractor -->
            <ProjectOverview
              v-if="hasEntries(contractorProjects)"
              :projects="contractorProjects"
              title="Aufträge"
              @clicked-on-project="setProjectAndGetMessages"
            />
          </v-list>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="7" lg="7" xl="7">
        <!-- applications:  -->
        <div class="text-h5" v-if="!selectedProject">
          Bitte Projekt auswählen
        </div>
        <v-card v-else>
          <v-toolbar color="tertiary20" flat>
            <v-toolbar-title
              >Projekt: {{ selectedProject.name }}</v-toolbar-title
            >
          </v-toolbar>

          <ApplicationOverview
            :applications="
              getApplications(
                selectedProject.role === 'Customer',
                selectedProject.id
              )
            "
            :userRole="selectedProject.role"
            :userId="user.id"
          />
          <div v-if="selectedProject.status !== 'Open'">
            <ProjectInProgressOverview />

            <ProjectClosedOverview />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectOverview from "@/components/dashboard/ProjectOverview.vue";
import ApplicationOverview from "@/components/dashboard/ApplicationOverview.vue";
import ProjectInProgressOverview from "@/components/dashboard/ProjectInProgressOverview.vue";
import ProjectClosedOverview from "@/components/dashboard/ProjectClosedOverview.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {
    ProjectOverview,
    ApplicationOverview,
    ProjectInProgressOverview,
    ProjectClosedOverview,
  },

  data() {
    return {
      show: false,
      customerProjects: [],
      contractorProjects: [],
      roleNotDefinedProjects: [],
    };
  },

  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      appliedAndUserProjects: (state) => state.project.appliedAndUserProjects,
      projectApplications: (state) => state.application.projectApplications,
      userApplications: (state) => state.application.userApplications,
      subscription: (state) => state.chat.subscription,
      user: (state) => state.user.user,
    }),
  },

  methods: {
    hasEntries(projectGroup) {
      return Array.isArray(projectGroup) && projectGroup.length;
    },

    getApplications(userIsCustomer, projectId) {
      if (userIsCustomer) {
        return this.projectApplications.filter(
          (application) => application.projectId === projectId
        );
      }
      return this.userApplications.filter(
        (application) => application.projectId === projectId
      );
    },

    getProject(projectId) {
      return this.appliedAndUserProjects.find(
        (project) => project.id === projectId
      );
    },

    async setProjectAndGetMessages(projectId) {
      const selectedProject = this.getProject(projectId);
      this.setSelectedProject(selectedProject);
      this.clearMessages();

      switch (selectedProject.status) {
        case "InProgress":
          await this.getMessages(projectId);
          await this.startLiveQuery(selectedProject);
          break;

        case "Closed":
          await this.getMessages(projectId);
          break;

        default:
          break;
      }
    },

    async allocateProjects() {
      this.appliedAndUserProjects.forEach(async (project) => {
        switch (project.role) {
          case "Customer":
            this.customerProjects.push(project);
            break;

          case "Contractor":
            this.contractorProjects.push(project);
            break;

          default:
            this.roleNotDefinedProjects.push(project);
        }
      });
    },

    ...mapActions([
      "fetchAppliedAndUserProjects",
      "fetchProjectApplications",
      "fetchUserApplications",
      "setSelectedProject",
      "cancelSubscription",
      "startLiveQuery",
      "getMessages",
      "clearMessages",
      "clearProjectApplications",
      "clearUserApplications",
    ]),
  },

  async created() {
    this.clearProjectApplications();
    this.clearUserApplications();
    await this.fetchAppliedAndUserProjects(this.user);
    await this.allocateProjects();
    if (this.selectedProject) {
      this.setProjectAndGetMessages(this.selectedProject.id);
    }

    await this.appliedAndUserProjects.forEach((project) => {
      if (project.role === "Customer") {
        this.fetchProjectApplications(project.id);
      } else {
        this.fetchUserApplications({
          projectId: project.id,
          user: this.user,
        });
      }
    });
  },

  destroyed() {
    if (this.subscription) {
      this.cancelSubscription();
    }
  },
};
</script>
<style scoped>
.chat {
  max-height: 20%;
}
</style>
