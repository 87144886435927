import axios from "axios";
import { store } from "@/store/store.js";

export const apiClientInstance = axios.create({
  withCredentials: false,
  baseURL: process.env.VUE_APP_BACKEND_PATH || "http://localhost:40000/",
  timeout: 5000,
  headers: {
    Accept: "application/JSON",
    "Content-Type": "application/JSON",
  },
});

apiClientInstance.interceptors.request.use(
  (config) => {
    setLoadingState(config.url);
    config = addAuthTokenToRequest(config);

    return config;
  },
  (error) => {
    setLoadingState(error.config.url);
    throw error;
  }
);

apiClientInstance.interceptors.response.use(
  (response) => {
    removeLoadingState(response.config.url);
    return response;
  },
  (error) => {
    removeLoadingState();
    throw error;
  }
);

function addAuthTokenToRequest(config) {
  const token = store.getters.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

function setLoadingState(url) {
  if (!url.includes("/message")) {
    store.dispatch("newRequest");
  }
}

function removeLoadingState(url) {
  if (!url) {
    store.dispatch("endRequest");
  } else if (!url.includes("/message")) {
    store.dispatch("endRequest");
  }
}
