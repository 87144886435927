import { apiClientInstance } from "./apiClientInstance.js";
import Parse from "parse";

export async function getMessages(projectId) {
  const messages = await apiClientInstance.get("/message/" + projectId);
  return messages.data;
}

export async function createMessage(payload) {
  await apiClientInstance.post("/message", payload);
}

export async function subscribe() {
  const query = new Parse.Query("Message");
  const subscription = await query.subscribe();

  return subscription;
}

export async function unsubscribe(subscription) {
  await subscription.unsubscribe();
}
export async function createConnection() {
  await Parse.initialize(process.env.VUE_APP_PARSE_SERVER_APPLICATION_ID ?? "yourappid", process.env.VUE_APP_PARSE_SERVER_MASTER_KEY ?? "masterkey");
  Parse.serverURL = process.env.VUE_APP_PARSE_PATH || "http://localhost:40009/parse";
}

export async function createSubscription(projectId) {
  let query = new Parse.Query("Message");
  query.equalTo("projectId", projectId);
  const subscription = await query.subscribe();
  return subscription;
}
