<template>
  <v-card data-testid="titleAndBio">
    <v-card-title> Titel & Biographie </v-card-title>
    <v-card-text
      ><v-form
        :disabled="locked"
        :readonly="!editing"
        @submit.prevent="saveData"
      >
        <v-row>
          <v-col cols="3">
            <v-subheader>Titel</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="title"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-subheader>Biographie</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="bio" auto-grow></v-textarea>
          </v-col>
        </v-row>
        <v-btn v-if="editing" @click="setEditMode(false)">Abbrechen</v-btn>
        <v-btn v-else :disabled="locked" @click="setEditMode(true)"
          >Bearbeiten
        </v-btn>
        <v-btn color="secondary" type="submit" :disabled="!editing"
          >Speichern</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TitleAndBio",
  props: ["user", "locked"],

  data() {
    return {
      editing: false,
      title: this.user.title,
      bio: this.user.bio,
    };
  },

  methods: {
    saveData() {
      const updatedUser = this.setPropertyValues(this.user);
      this.updateUser(updatedUser);
      this.setEditMode(false);
    },

    setEditMode(edit) {
      if (edit) {
        this.$emit("editing", "titleAndBio");
        this.editing = true;
      } else {
        this.$emit("editing", "");
        this.editing = false;
        this.resetForm();
      }
    },

    setPropertyValues(user) {
      user.title = this.title;
      user.bio = this.bio;
      return user;
    },

    resetForm() {
      this.title = this.user.title;
      this.bio = this.user.bio;
    },

    ...mapActions(["updateUser"]),
  },
};
</script>

<style scoped>
.v-btn {
  margin-right: 8px;
}
</style>
