export const translations = (item) => {
  const translated = translationList[item];
  if (translated === undefined) {
    return item;
  }
  return translated;
};

const translationList = {
  CreateConstruction: "eine Konstruktion beauftragen",
  ProofSolution: "Lösungen überpüfen lassen",
  AnalyticalSolution: "analytische Lösungen finden",
  Goals: "Ziele",
  Guidelines: "Richtlinien",
  ProductionNumbers: "Produktionszahlen",
  CompanySize: "Unternehmensgrösse",
  BudgetRanges: "Budget",
  SkillLevel: "Könnerstufe",
  UnwantedProductionMethods: "Unerwünschte Fertigungsmethoden",
  typeName: "Kategorie",
  name: "Name",
  detail: "Detail",
  plannedStart: "Geplanter Projektstart",
  provisionalPrice: "Richtpreis",
  Accepted: "Akzeptiert",
  Rejected: "Abgelehnt",
  Withdrawn: "Zurückgezogen",
  userName: "Ausschreiber",
};
