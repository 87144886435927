<template>
  <div>
    <v-btn text rounded :to="{ name: 'Project' }" exact color="#fff"
      >Projekte
    </v-btn>

    <v-btn text rounded :to="{ name: 'ChooseType' }" exact color="#fff">
      Neues Projekt
    </v-btn>

    <v-btn text rounded :to="{ name: 'Dashboard' }" exact color="#fff"
      >Dashboard
    </v-btn>

    <v-btn
      :to="loggedIn ? { name: 'UserOverview' } : { name: 'Login' }"
      icon
      color="#fff"
      ><v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn v-if="loggedIn" @click="logout()" icon color="#fff"
      ><v-icon>mdi-logout</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Navbar",

  props: ["loggedIn"],

  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style></style>
