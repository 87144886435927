import { render, staticRenderFns } from "./Topic.vue?vue&type=template&id=1eb65470&scoped=true"
import script from "./Topic.vue?vue&type=script&lang=js"
export * from "./Topic.vue?vue&type=script&lang=js"
import style0 from "./Topic.vue?vue&type=style&index=0&id=1eb65470&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eb65470",
  null
  
)

export default component.exports