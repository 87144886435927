import * as ApplicationService from "@/services/ApplicationService";

export const state = {
  projectApplications: [],
  userApplications: [],
};

export const mutations = {
  ADD_PROJECT_APPLICATIONS(state, applications) {
    applications.forEach((application) => {
      state.projectApplications.push(application);
    });
  },

  ADD_USER_APPLICATIONS(state, applications) {
    applications.forEach((application) => {
      state.userApplications.push(application);
    });
  },

  CLEAR_PROJECT_APPLICATIONS(state) {
    state.projectApplications = [];
  },

  CLEAR_USER_APPLICATIONS(state) {
    state.userApplications = [];
  },
};

export const actions = {
  async fetchProjectApplications({ dispatch, commit }, projectId) {
    await ApplicationService.getProjectApplications(projectId)

      .then(async (applications) => {
        const applicationsWithUser = await Promise.all(
          applications.map(async (application) => {
            const user = await ApplicationService.getApplicantUser(
              application.userId
            );
            application.user = user;
            return application;
          })
        );

        commit("ADD_PROJECT_APPLICATIONS", applicationsWithUser);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          commit("ADD_PROJECT_APPLICATIONS", []);
        } else {
          const notification = {
            type: "error",
            message:
              "Es gab ein Problem beim laden der Bewerbungen: " + error.message,
          };
          dispatch("addNotification", notification);
        }
      });
  },

  async fetchUserApplications({ dispatch, commit }, { projectId, user }) {
    await ApplicationService.getUserApplications(projectId, user.id)

      .then((applications) => {
        const applicationsWithUser = applications.map((application) => {
          application.user = user;
          return application;
        });

        commit("ADD_USER_APPLICATIONS", applicationsWithUser);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          commit("ADD_USER_APPLICATIONS", []);
        } else {
          const notification = {
            type: "error",
            message:
              "Es gab ein Problem beim laden Deiner Bewerbungen: " +
              error.message,
          };
          dispatch("addNotification", notification);
        }
      });
  },

  async createApplication({ dispatch }, payload) {
    let status;
    let notification;

    await ApplicationService.createApplication(payload)

      .then((response) => {
        notification = {
          type: "success",
          message: "Deine Bewerbung wurde erstellt!",
        };
        status = response.status;
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          notification = {
            type: "error",
            message: "Bewerbung auf eigenes Projekt nicht möglich",
          };
        } else {
          notification = {
            type: "error",
            message:
              "Es gab ein Problem beim erstellen deiner Bewerbung: " +
              error.message,
          };
        }
        status = error.response.status;
      });

    dispatch("addNotification", notification);

    return status;
  },

  async rejectApplication({ dispatch }, applicationId) {
    await ApplicationService.applyApplicationStatus(
      applicationId,
      "Rejected"
    ).catch((error) => {
      const notification = {
        type: "error",
        message:
          "Es gab ein Problem beim Ablehnen der Bewerbung: " + error.message,
      };
      dispatch("addNotification", notification);
    });
  },

  async withdrawApplication({ dispatch }, applicationId) {
    await ApplicationService.applyApplicationStatus(
      applicationId,
      "Withdrawn"
    ).catch((error) => {
      const notification = {
        type: "error",
        message:
          "Es gab ein Problem beim Zurückziehen der Bewerbung: " +
          error.message,
      };
      dispatch("addNotification", notification);
    });
  },

  async acceptApplication({ dispatch }, payload) {
    await ApplicationService.acceptApplication(payload).catch(() => {
      const notification = {
        type: "error",
        message: "Es gab ein Problem bei der Annahme der Bewerbung",
      };
      dispatch("addNotification", notification);
    });
  },

  clearProjectApplications({ commit }) {
    commit("CLEAR_PROJECT_APPLICATIONS");
  },

  clearUserApplications({ commit }) {
    commit("CLEAR_USER_APPLICATIONS");
  },
};
