import { apiClientInstance } from "./apiClientInstance.js";

export const getUser = async (id) => {
  const user = await apiClientInstance.get("/user/" + id);
  return user.data;
};

export const updateUser = async (user) => {
  const updatedUser = await apiClientInstance.put("/user", user);
  return updatedUser.status;
};

export const updateUserEmail = async (user) => {
  const updatedUser = await apiClientInstance.put("/auth", user);
  return updatedUser.status;
};
