var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stepper',{staticClass:"ma-16",attrs:{"data-testId":"stepper"},model:{value:(_vm.currentTopic.number),callback:function ($$v) {_vm.$set(_vm.currentTopic, "number", $$v)},expression:"currentTopic.number"}},[_c('v-stepper-items',[_vm._l((_vm.getTotalTopicsForWizard),function(topicData,index){return _c('v-stepper-content',{key:index,attrs:{"step":index + 1}},[_c('Topic',{attrs:{"topicData":topicData,"topicName":_vm.topicNames[index]}}),(_vm.showError)?_c('v-alert',{attrs:{"type":"warning","icon":"mdi-alert"}},[_vm._v(" Bitte Option auswählen ")]):_vm._e()],1)}),_c('v-stepper-content',{key:_vm.getTotalTopicsForWizard.length,attrs:{"step":_vm.getTotalTopicsForWizard.length + 1}},[_c('TopicSummary')],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"9","xl":"9"}},[_c('v-progress-linear',{attrs:{"value":_vm.progress}})],1),_c('v-col',[_c('v-row',{staticClass:"stepperButtons"},[_c('v-flex',{staticClass:"d-flex justify-center"},[(_vm.currentTopic.number != 1)?_c('v-btn',{attrs:{"data-testid":"lastTopic","text":""},on:{"click":function($event){return _vm.lastTopic()}}},[_vm._v(" zurück ")]):_vm._e(),(
                  _vm.currentTopic.number != _vm.getTotalTopicsForWizard.length + 1
                )?_c('v-btn',{attrs:{"data-testid":"nextTopic","color":"secondary"},on:{"click":function($event){return _vm.nextTopic()}}},[_vm._v(" weiter ")]):_vm._e(),(
                  _vm.currentTopic.number === _vm.getTotalTopicsForWizard.length + 1
                )?_c('v-btn',{attrs:{"to":{
                  name: 'ProjectForm',
                },"exact":"","color":"secondary"}},[_vm._v("Loslegen ")]):_vm._e()],1)],1)],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }