var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-navigation-drawer',{attrs:{"width":"auto","permanent":"","floating":""}},[_c('v-card-title',[_vm._v("Projekte filtern")]),_c('v-divider'),_c('v-select',{attrs:{"items":_vm.types,"item-text":"translated","clearable":"","filled":"","dense":"","label":"Kategorie","return-object":""},on:{"change":function($event){return _vm.filterProjects()},"click:clear":function($event){return _vm.clearFromLocalStorage('type', _vm.type)}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-select',{attrs:{"items":_vm.getGeneralInfoTypeTags('BudgetRanges'),"item-text":"detail","clearable":"","filled":"","dense":"","label":"Budget","return-object":""},on:{"change":function($event){return _vm.filterProjects()}},model:{value:(_vm.budgetRange),callback:function ($$v) {_vm.budgetRange=$$v},expression:"budgetRange"}}),_c('v-select',{attrs:{"items":_vm.getGeneralInfoTypeTags('CompanySize'),"item-text":"detail","clearable":"","filled":"","dense":"","label":"Unternehmensgrösse","return-object":""},on:{"change":function($event){return _vm.filterProjects()}},model:{value:(_vm.companySize),callback:function ($$v) {_vm.companySize=$$v},expression:"companySize"}}),_c('v-select',{attrs:{"items":_vm.getGeneralInfoTypeTags('SkillLevel'),"item-text":"name","item-value":"detail","clearable":"","filled":"","dense":"","label":"Könnerstufe","return-object":""},on:{"change":function($event){return _vm.filterProjects()}},model:{value:(_vm.skillLevel),callback:function ($$v) {_vm.skillLevel=$$v},expression:"skillLevel"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-select',{attrs:{"items":_vm.sortCriteria,"label":"Sortieren nach"},on:{"change":function($event){return _vm.sortProjects({
            selectedOrderBy: _vm.selectedOrderBy,
            orderByMap: _vm.orderByMap,
          })}},model:{value:(_vm.selectedOrderBy),callback:function ($$v) {_vm.selectedOrderBy=$$v},expression:"selectedOrderBy"}}),_c('div',{staticClass:"text-h7 font-italic"},[_vm._v(" Resultate: "+_vm._s(_vm.getOpenProjects ? _vm.getOpenProjects.length : 0)+" ")]),_vm._l((_vm.paginatedProjects),function(project){return _c('div',{key:project.id},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"project",class:{ 'on-hover': hover },attrs:{"color":"tertiary40","elevation":hover ? 12 : 2,"to":{
              name: 'ProjectDetail',
              params: { id: project.id },
            },"exact":""}},[_c('v-card-title',{staticClass:"d-flex flex-row justify-space-between"},[_c('span',[_vm._v(_vm._s(project.name))]),_c('span',[_c('v-chip',{staticClass:"project-attribute project-price justify-center"},[_c('span',[_vm._v(_vm._s(project.provisionalPrice)+" CHF")])]),_c('v-chip',{staticClass:"project-attribute project-plannedstart justify-center"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(project.plannedStart)))])])],1)]),_c('v-card-text',[_vm._v(_vm._s(_vm.truncatedText(project.detail)))])],1)]}}],null,true)})],1)})],2)],1),_c('v-pagination',{attrs:{"color":"secondary","length":_vm.numberOfPages},on:{"input":function($event){return _vm.jump()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }