<template>
  <v-app data-testid="app">
    <!-- Header-->
    <v-app-bar app color="primary">
      <router-link :to="{ name: 'Project' }">
        <v-img
          src="@/shared/assets/patriz-logo.png"
          height="30"
          max-width="200"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        class="d-md-none"
        color="#FFF"
        @click.stop="showMobileNavigation"
      ></v-app-bar-nav-icon>

      <Navbar class="d-none d-md-block" :loggedIn="loggedIn" />
    </v-app-bar>
    <Hamburger class="d-md-none" ref="mobileNav" :loggedIn="loggedIn" />

    <!-- Main -->
    <v-main>
      <NotificationContainer />
      <v-overlay v-if="isLoading"
        ><v-progress-circular
          indeterminate
          color="secondary"
          :width="5"
          :size="100"
        ></v-progress-circular>
      </v-overlay>

      <router-view
    /></v-main>

    <!-- Footer -->
    <v-footer color="secondary">
      <v-row justify="space-around">
        <strong
          ><a id="home" href="https://patriz.ai/"
            >{{ new Date().getFullYear() }} — PATRIZ AG</a
          ></strong
        >
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import NotificationContainer from "@/components/notification/NotificationContainer.vue";
import Navbar from "@/components/navigation/Navbar.vue";
import Hamburger from "@/components/navigation/Hamburger.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  data() {
    return {
      showDrawer: false,
    };
  },

  components: {
    NotificationContainer,
    Navbar,
    Hamburger,
  },

  computed: {
    ...mapGetters(["loggedIn", "isLoading"]),
  },

  methods: {
    showMobileNavigation() {
      this.$refs.mobileNav.setDrawer(!this.showDrawer);
    },

    ...mapActions(["fetchTypes"]),
  },
  async created() {
    await this.fetchTypes();
  },
};
</script>

<style>
/* disable the scrollbar that vuetifyl places by default */
html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html body {
  height: 100%;
}
.v-main {
  position: relative;

  z-index: 0;
}

.text-h4 {
  margin-left: 25px;
  margin-top: 15px;
}

.notification-container {
  position: fixed;
  top: 5em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.v-footer {
  height: 60px;
}

#home {
  color: #fff;
  text-decoration: none;
}
</style>
