<template>
  <v-container>
    <div class="text-h4">Übersicht neues Projekt</div>
    <v-row>
      <v-col
        v-for="(topic, name) in selectedTags"
        :key="JSON.stringify(topic)"
        cols="12"
        md="6"
        lg="4"
        xl="4"
      >
        <v-card color="tertiary40" min-height="150px">
          <v-card-title class="title">{{ translate(name) }}</v-card-title>
          <v-card-subtitle
            class="subtitle"
            v-for="tag in topic"
            :key="JSON.stringify(tag)"
            >{{ tag.name }}</v-card-subtitle
          >
          <v-card-text></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { translations } from "@/shared/translations";
import { mapState } from "vuex";

export default {
  name: "TopicSummary",
  methods: {
    translate: translations,
  },
  computed: {
    ...mapState({ selectedTags: (state) => state.wizard.selectedTags }),
  },
};
</script>

<style scoped>
.title {
  word-break: normal;
}

.subtitle {
  padding-left: 16px;
  padding-top: 0px;
  padding-bottom: 2px;
}
</style>
