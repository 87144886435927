<template>
  <v-container>
    <v-row>
      <v-card data-testid="login">
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login({ email, password })">
            <v-container>
              <v-row>
                <v-text-field
                  v-model="email"
                  label="Email"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :type="'password'"
                  v-model="password"
                  label="Passwort"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-btn class="mr-4" type="submit"> Login </v-btn>
              </v-row>
            </v-container>
          </v-form>
          <v-container>
            Noch kein Login? Dann
            <router-link :to="{ name: 'Registration' }"
              >registrieren</router-link
            >
            Sie sich jetzt!
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
  },
};
</script>

<style>
.v-card {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  min-width: 40%;
}
</style>
