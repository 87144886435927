import { apiClientInstance } from "./apiClientInstance";

export const getProjects = async (filterCriteria) => {
  if (filterCriteria) {
    let queryString = "";
    let keys = Object.keys(filterCriteria);

    for (let key of keys) {
      queryString = prepareQueryString(queryString, key, filterCriteria[key]);
    }

    const projects = await apiClientInstance.get("/project" + queryString);
    return await projects.data;
  } else {
    const projects = await apiClientInstance.get("/project");
    return projects.data;
  }
};

export const getProject = async (id) => {
  const project = await apiClientInstance.get("/project/" + id);
  return project.data;
};

export const getAppliedAndUserProjectsByUserId = async (userId) => {
  const projects = await apiClientInstance.get("/project?userId=" + userId);
  return projects.data;
};

export const createProject = async (payload) => {
  return await apiClientInstance.post("/project", payload);
};

export const initializeProject = async (payload) => {
  return await apiClientInstance.post("/initialization", payload);
};

export const getProjectUsers = async (projectId) => {
  const users = await apiClientInstance.get("/userInfo?projectId=" + projectId);
  return users.data;
};

export const closeProject = async (projectId) => {
  const project = await apiClientInstance.patch("/closing/" + projectId);
  return project.data;
};

export function prepareQueryString(queryString, parameterType, parameter) {
  let result = queryString;

  for (let i = 0; i < parameter.length; i++) {
    if (result === "") {
      result += "?" + parameterType + "=" + parameter[i];
    } else {
      result += "&" + parameterType + "=" + parameter[i];
    }
  }
  return result;
}
