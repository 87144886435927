<template>
  <v-container fluid>
    <div class="text-h4">Projektbriefing</div>
    <v-row v-if="details">
      <v-col cols="12" md="7" lg="7" xl="7">
        <v-card elevation="1" rounded>
          <v-toolbar color="primary20" flat>
            <v-btn icon class="hidden-xs-only" @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ projectWithTags.name }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row v-for="(detail, key) in details" :key="key">
              <v-col cols="3">
                <v-card-subtitle>{{ translate(key) }}</v-card-subtitle>
              </v-col>
              <v-col cols="19">
                <v-card-subtitle data-testId="projectDetail">
                  {{ detail }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5">
        <ShowTopicsAndTags v-if="projectTags" :projectTags="projectTags" />
      </v-col>
    </v-row>
    <ApplicationForm
      v-if="projectTags"
      class="application"
      :project="projectWithTags"
      :projectTags="projectTags"
    />
  </v-container>
</template>

<script>
import ShowTopicsAndTags from "@/components/projectWizard/ShowTopicsAndTags.vue";
import { translations } from "@/shared/translations";
import ApplicationForm from "@/components/application/ApplicationForm.vue";
import { groupBy } from "@/shared/groupBy.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "ProjectDetail",
  components: {
    ApplicationForm,
    ShowTopicsAndTags,
  },

  computed: {
    projectTags() {
      if (this.projectWithTags.tags) {
        const tagProjects = this.projectWithTags.tags;
        const group = this.groupByTopicId(tagProjects);
        let result = {};

        Object.keys(group).forEach((key) => {
          const topic = this.getTotalTopicsForProjectDetail.find(
            (topic) => topic.id == key
          );

          result[[topic.name]] = group[key];
        });
        return result;
      } else return null;
    },

    details() {
      if (!Object.keys(this.projectWithTags).length) {
        return null;
      }

      let { name, detail, plannedStart, provisionalPrice, typeId, users } =
        this.projectWithTags;

      const typeName = this.getTypeName(typeId);
      provisionalPrice = this.formatPrice(provisionalPrice);
      const user = users[0];
      const userName = user.firstname + " " + user.lastname;

      return {
        name,
        userName,
        detail,
        plannedStart,
        provisionalPrice,
        typeName,
      };
    },

    ...mapState({ projectWithTags: (state) => state.project.projectWithTags }),
    ...mapGetters(["getTotalTopicsForProjectDetail", "getTypeName"]),
  },
  methods: {
    translate(word) {
      return translations(word);
    },

    groupByTopicId(tagProjects) {
      return groupBy(tagProjects, "topicId");
    },

    formatPrice(price) {
      if (price) {
        return price.toLocaleString("de-DE", {
          style: "currency",
          currency: "CHF",
        });
      } else return "";
    },

    ...mapActions(["fetchProjectWithTags"]),
  },

  async created() {
    const status = await this.fetchProjectWithTags(this.$route.params.id);
    if (status && status >= 400) {
      this.$router.push({ name: "Project" });
    }
  },
};
</script>

<style scoped>
.v-sheet {
  margin: 25px;
}

.v-toolbar {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.v-toolbar__title,
.v-btn .v-icon {
  color: white;
}

.v-card__subtitle {
  padding: 8px;
}

.text-h4 {
  padding-left: 25px;
  padding-top: 15px;
}

.application {
  margin-bottom: 25px;
}
</style>
