import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import * as project from "./modules/project.js";
import * as wizard from "./modules/wizard.js";
import * as notification from "./modules/notification.js";
import * as application from "./modules/application.js";
import * as chat from "./modules/chat.js";
import * as user from "./modules/user.js";
import * as file from "./modules/file.js";
import * as loading from "./modules/loading.js";

const dataState = createPersistedState({
  paths: ["user.user", "user.token", "project.selectedProject"],
});

Vue.use(Vuex);

export let store = new Vuex.Store({
  actions: {},

  modules: {
    project,
    wizard,
    notification,
    application,
    chat,
    user,
    file,
    loading,
  },

  plugins: [dataState],
});

export default store;
