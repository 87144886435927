<template>
  <v-stepper data-testId="stepper" v-model="currentTopic.number" class="ma-16">
    <v-stepper-items>
      <!-- Create the individual topics -->
      <v-stepper-content
        v-for="(topicData, index) in getTotalTopicsForWizard"
        :key="index"
        :step="index + 1"
      >
        <Topic :topicData="topicData" :topicName="topicNames[index]" />
        <v-alert v-if="showError" type="warning" icon="mdi-alert">
          Bitte Option auswählen
        </v-alert>
      </v-stepper-content>
      <v-stepper-content
        :step="getTotalTopicsForWizard.length + 1"
        :key="getTotalTopicsForWizard.length"
      >
        <TopicSummary />
      </v-stepper-content>

      <!-- Create progress bar and control buttons -->
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="8" lg="9" xl="9">
            <v-progress-linear :value="progress"></v-progress-linear>
          </v-col>
          <v-col>
            <v-row class="stepperButtons">
              <v-flex class="d-flex justify-center">
                <v-btn
                  data-testid="lastTopic"
                  v-if="currentTopic.number != 1"
                  text
                  @click="lastTopic()"
                >
                  zurück
                </v-btn>
                <v-btn
                  data-testid="nextTopic"
                  v-if="
                    currentTopic.number != getTotalTopicsForWizard.length + 1
                  "
                  color="secondary"
                  @click="nextTopic()"
                >
                  weiter
                </v-btn>
                <v-btn
                  v-if="
                    currentTopic.number === getTotalTopicsForWizard.length + 1
                  "
                  :to="{
                    name: 'ProjectForm',
                  }"
                  exact
                  color="secondary"
                  >Loslegen
                </v-btn>
              </v-flex>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Topic from "@/components/projectWizard/Topic";
import TopicSummary from "@/components/projectWizard/TopicSummary";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectWizard",
  components: {
    Topic,
    TopicSummary,
  },

  data() {
    return {
      currentTopic: { number: 1, name: "" },
      showError: false,
    };
  },

  methods: {
    nextTopic() {
      if (this.topicHasSelectedTags(this.currentTopic.name)) {
        this.showError = false;
        this.currentTopic.name = this.topicNames[this.currentTopic.number];
        this.currentTopic.number++;
      } else {
        this.showError = true;
      }
    },

    lastTopic() {
      this.showError = false;
      this.currentTopic.number--;
      this.currentTopic.name = this.topicNames[this.currentTopic.number - 1];
    },

    ...mapActions(["setSelectedType"]),
  },

  computed: {
    topicNames() {
      return this.getTotalTopicsForWizard.map((topic) => topic.name);
    },

    progress() {
      return (
        (this.currentTopic.number / (this.getTotalTopicsForWizard.length + 1)) *
        100
      );
    },

    ...mapGetters(["topicHasSelectedTags", "getTotalTopicsForWizard"]),
  },

  async created() {
    await this.setSelectedType(this.$route.params.type);
    this.currentTopic.name = this.topicNames[0];
  },
};
</script>

<style scoped>
.v-alert {
  position: fixed;
  top: 5em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.stepperButtons {
  padding-bottom: 25px;
}
</style>
