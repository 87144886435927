export function groupBy(objectArray, property) {
  return objectArray.reduce((accumulated, obj) => {
    const key = obj[property];
    if (!accumulated[key]) {
      accumulated[key] = [];
    }
    accumulated[key].push(obj);
    return accumulated;
  }, {});
}
