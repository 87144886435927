<template>
  <v-card data-testId="projectClosed">
    <v-toolbar color="tertiary40" flat>
      <v-toolbar-title
        >Abschluss
        <v-chip v-if="isDueToPay" class="ma-2" color="error" text-color="#FFF"
          >Noch nicht vorfinanziert
        </v-chip>
        <v-chip v-if="isPaid" class="ma-2" color="success" text-color="#FFF"
          >Bezahlt
        </v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <ActionDialog
        class="closeDialog"
        v-if="isCloseable && selectedProject.role === 'Customer'"
        action="Projekt beenden"
        actionColor="error"
        :actionDescription="'Möchtest Du das Projekt beenden?'"
        actionEvent="close-project"
        @close-project="closeProjectAndRefresh()"
      />
    </v-toolbar>

    <v-alert v-if="isClosed" type="success">
      Das Projekt wurde erfolgreich abgeschlossen!
    </v-alert>
  </v-card>
</template>

<script>
import ActionDialog from "@/components/application/ActionDialog.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ProjectClosedOverview",
  components: { ActionDialog },
  computed: {
    isDueToPay() {
      return this.selectedProject.status === "InProgress" && !this.isPaid;
    },

    isPaid() {
      return (
        this.selectedProject.balance >= this.selectedProject.agreedPrice &&
        this.selectedProject.balance > 0
      );
    },

    isCloseable() {
      return this.selectedProject.status === "InProgress" && this.isPaid;
    },

    isClosed() {
      return this.selectedProject.status === "Closed";
    },

    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
    }),
  },

  methods: {
    async closeProjectAndRefresh() {
      await this.closeProject(this.selectedProject.id);
      this.$router.go(0);
    },

    ...mapActions(["closeProject"]),
  },
};
</script>
