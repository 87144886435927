<template>
  <v-container>
    <v-row>
      <v-card data-testid="registration">
        <v-card-title>Registrieren</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitData">
            <v-container>
              <v-row>
                <v-text-field
                  v-model="firstname"
                  :rules="firstnameRules"
                  label="Vorname"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="lastname"
                  :rules="lastnameRules"
                  label="Nachname"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :type="'password'"
                  v-model="password"
                  :rules="passwordRules"
                  label="Passwort"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-btn class="mr-4" type="submit"> Registrieren </v-btn>
              </v-row>
            </v-container>
          </v-form>
          <v-container>
            Bereits ein Account? Hier geht's zum
            <router-link :to="{ name: 'Login' }">Login</router-link>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      valid: true,
      firstnameRules: [(v) => !!v || "Bitte Deinen Vornamen angeben"],
      lastnameRules: [(v) => !!v || "Bitte Deinen Nachnamen angeben"],
      emailRules: [
        (v) => !!v || "Bitte  E-Mail angeben",
        (v) => /.+@.+\..+/.test(v) || "Bitte gültige E-Mail angeben",
      ],
      passwordRules: [(v) => !!v || "Bitte ein Passwort angeben"],
    };
  },
  methods: {
    submitData() {
      this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
        };
        this.register(payload);
      }
    },
    ...mapActions(["register"]),
  },
};
</script>

<style></style>
