<template>
  <v-card data-testId="projectInProgress">
    <v-toolbar color="tertiary40" flat>
      <v-toolbar-title>Durchführung</v-toolbar-title>
    </v-toolbar>
    <Chat :currentChatUser="user" :partnerChatUser="findChatPartner()" />
    <v-divider></v-divider>
    <File :userId="user.id" />
  </v-card>
</template>

<script>
import Chat from "@/components/chat/Chat.vue";
import File from "@/components/dashboard/File.vue";
import { mapState } from "vuex";

export default {
  name: "ProjectInProgressOverview",
  components: { Chat, File },

  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      user: (state) => state.user.user,
    }),
  },

  methods: {
    findChatPartner() {
      return this.selectedProject.users.find((user) => user.id != this.user.id);
    },
  },
};
</script>
