<template>
  <v-container data-testId="file">
    <v-file-input
      :disabled="selectedProject.status === 'Closed'"
      :rules="rules"
      v-model="file"
      type="file"
      label="Datei hochladen"
    ></v-file-input>
    <v-alert v-if="file" type="warning" color="#FFD990">
      Falls bereits eine Datei mit diesem Namen existiert, wird diese
      überschrieben!
    </v-alert>
    <v-btn :disabled="!file" @click="uploadFile()">ok</v-btn>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="downloadFiles"
          v-bind="attrs"
          v-on="on"
          @click="downloadFiles()"
          >Projektdateien herunterladen
        </v-btn>
      </template>
      <span
        >Alle Deine Projektdateien werden in einem Zip-Ordner heruntergeladen
      </span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "File",
  props: ["userId"],
  data() {
    return {
      file: null,
      rules: [(v) => !v || v.size <= 50000000 || "Dateigrösse maximal 50 MB!"],
    };
  },

  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
    }),
  },

  methods: {
    async uploadFile() {
      await this.addFile({
        projectId: this.selectedProject.id,
        file: this.file,
        userId: this.userId,
      });
      this.file = null;
    },

    async downloadFiles() {
      await this.fetchFiles({
        projectId: this.selectedProject.id,
        userId: this.userId,
      });
    },

    ...mapActions(["addFile", "fetchFiles"]),
  },
};
</script>

<style scoped>
.downloadFiles {
  margin-left: 8px;
}
</style>
