import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#08262C",
        primary20: "#187182",
        secondary: "#4597CB",
        secondary20: "#95c4e1",
        secondary40: "#e4f0f8",
        tertiary: "#5C6670",
        tertiary20: "#8f99a3",
        tertiary40: "#c7ccd1",
        success: "#6ECB89",
        success20: "#b9e6c6",
        error: "#F4858E",
        error10: "#f8b4b9",
        warning: "#FFD990",
        warning10: "#ffeac3",
        info: "#4597CB",
      },
    },
  },
});
