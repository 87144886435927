import * as ChatService from "@/services/ChatService.js";

export const state = {
  messages: [],
  subscription: null,
};

export const mutations = {
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },

  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },

  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  async getMessages({ dispatch, commit }, projectId) {
    await ChatService.getMessages(projectId)
      .then((response) => {
        const orderedMessages = response.sort((a, b) => {
          const dateA = Date.parse(a.createdAt);
          const dateB = Date.parse(b.createdAt);

          return dateA - dateB;
        });
        commit("SET_MESSAGES", orderedMessages);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "Es gab ein Problem beim laden deiner Nachrichten: " +
            error.message,
        };
        dispatch("addNotification", notification);
      });
  },

  async createMessage({ dispatch }, message) {
    await ChatService.createMessage(message).catch((error) => {
      const notification = {
        type: "error",
        message:
          "Es gab ein Problem beim laden der Nachrichten: " + error.message,
      };
      dispatch("addNotification", notification);
    });
  },

  async createSubscription({ commit }, projectId) {
    ChatService.createConnection();
    const subscription = await ChatService.createSubscription(projectId);
    commit("SET_SUBSCRIPTION", subscription);
  },

  startLiveQuery({ dispatch, state }, project) {
    if (state.subscription) {
      dispatch("cancelSubscription");
    }
    if (project.status !== "Open") {
      dispatch("createSubscription", project.id).then(() => {
        dispatch("listenForMessages");
      });
    }
  },

  listenForMessages({ commit, state }) {
    state.subscription.on("create", (message) => {
      const parsedMessage = JSON.parse(JSON.stringify(message));
      commit("ADD_MESSAGE", parsedMessage);
    });
  },

  async cancelSubscription({ commit, state }) {
    await ChatService.unsubscribe(state.subscription);
    commit("SET_SUBSCRIPTION", null);
  },

  clearMessages({ commit }) {
    commit("SET_MESSAGES", []);
  },
};
