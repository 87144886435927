import { apiClientInstance } from "./apiClientInstance.js";

export const uploadFile = async (payload) => {
  const { projectId, file, userId } = payload;
  let formData = new FormData();
  formData.append("formFiles", file);

  return await apiClientInstance.post(
    "/file/upload?subDirectory=" + projectId + "&userId=" + userId,
    formData,
    {
      headers: {
        Accept: "text/plain",
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const downloadFiles = async (payload) => {
  const { projectId, userId } = payload;
  return await apiClientInstance.get(
    "/file/download?subDirectory=" + projectId + "&userId=" + userId,
    {
      responseType: "blob",
      headers: {
        Accept: "*/*",
      },
    }
  );
};
