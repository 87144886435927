import * as UserService from "@/services/UserService.js";
import * as LoginService from "@/services/LoginService.js";
import router from "@/router";

export const state = {
  user: null,
  error: false,
  token: null,
};

export const mutations = {
  SET_TOKEN_DATA(state, accessToken) {
    state.token = accessToken;
  },

  CLEAR_TOKEN_AND_USER_DATA(state) {
    state.user = null;
    state.error = false;
    state.token = null;
    localStorage.removeItem("vuex");
  },

  SET_CURRENT_USER(state, user) {
    state.user = user;
  },
};

export const actions = {
  async fetchUser({ commit, dispatch }, id) {
    await UserService.getUser(id)

      .then((response) => {
        commit("SET_CURRENT_USER", response);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Es gab ein Problem beim laden des Users: " + error.message,
        };

        dispatch("addNotification", notification);
      });
  },

  async updateUser({ dispatch }, user) {
    await UserService.updateUser(user)

      .then(() => dispatch("fetchUser", user.id))
      .catch(() => {
        const notification = {
          type: "error",
          message:
            "Es ist ein Fehler aufgetreten, die Daten konnten leider nicht aktualisiert werden",
        };

        dispatch("addNotification", notification);
      });
  },

  async updateUserEmail({ dispatch }, user) {
    await UserService.updateUserEmail(user)

      .then(() => dispatch("fetchUser", user.id))
      .catch(() => {
        let notification;
        notification = {
          type: "error",
          message:
            "Es ist ein Fehler aufgetreten, die Daten konnten leider nicht aktualisiert werden",
        };

        dispatch("addNotification", notification);
      });
  },

  async login({ commit, dispatch }, credentials) {
    await LoginService.login(credentials)

      .then(({ data }) => {
        commit("SET_TOKEN_DATA", data.token);
        dispatch("fetchUser", data.id);
      })
      .then(() => router.push({ name: "Project" }))
      .catch(() => {
        const notification = {
          type: "error",
          message: "Username oder Passwort nicht korrekt",
        };
        dispatch("addNotification", notification);
      });
  },

  logout({ commit, dispatch }) {
    commit("CLEAR_TOKEN_AND_USER_DATA");
    dispatch("clearUserApplications");
    dispatch("clearProjectApplications");
    dispatch("clearProjects");
    dispatch("clearMessages");

    router.push({ name: "Login" });
  },

  async register({ commit, dispatch }, credentials) {
    await LoginService.register(credentials)

      .then(({ data }) => {
        commit("SET_TOKEN_DATA", data.token);
        dispatch("fetchUser", data.id);
      })
      .then(() => router.push({ name: "Project" }))
      .catch(() => {
        const notification = {
          type: "error",
          message: "Registrierung konnte nicht durchgeführt werden",
        };
        dispatch("addNotification", notification);
      });
  },
};

export const getters = {
  getUserId: (state) => {
    return state.user ? state.user.id : null;
  },

  loggedIn: (state) => {
    return !!state.token;
  },

  token: (state) => {
    return state.token;
  },
};
