import * as ProjectService from "@/services/ProjectService";
import * as TagProjectService from "@/services/TagProjectService";
import { formatDate } from "@/shared/formatDate.js";

export const state = {
  projects: [],
  selectedProject: null,
  projectWithTags: {},
  appliedAndUserProjects: [],
};

export const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },

  SET_SELECTED_PROJECT(state, project) {
    state.selectedProject = project;
  },

  SET_PROJECT_WITH_TAGS(state, project) {
    project.plannedStart = formatDate(project.plannedStart);
    state.projectWithTags = project;
  },

  SET_PROJECTS_OF_USER(state, projects) {
    state.appliedAndUserProjects = projects;
  },

  CLEAR_ALL_PROJECTS(state) {
    state.projects = [];
    state.selectedProject = null;
    state.projectWithTags = {};
    state.appliedAndUserProjects = [];
  },
};

export const actions = {
  async fetchProjects({ commit, dispatch }, filterCriteria) {
    await ProjectService.getProjects(filterCriteria)

      .then((response) => commit("SET_PROJECTS", response))
      .catch((error) => {
        if (error.response.status === 404) {
          commit("SET_PROJECTS", []);
        } else {
          const notification = {
            type: "error",
            message:
              "Es gab ein Problem beim laden der Projekte: " + error.message,
          };
          dispatch("addNotification", notification);
        }
      });
  },

  async fetchProjectWithTags({ commit, dispatch }, projectId) {
    let status;

    await ProjectService.getProject(projectId)
      .then(async (projectResponse) => {
        status = projectResponse.status;

        const tags = await getTagProjects(projectId);
        projectResponse.tags = tags;

        const users = await getProjectUsers(projectId);
        projectResponse.users = users;

        commit("SET_PROJECT_WITH_TAGS", projectResponse);
      })
      .catch((error) => {
        let notification;

        notification = {
          type: "error",
          message:
            "Es gab ein Problem beim laden des Projekts: " + error.message,
        };

        dispatch("addNotification", notification);
        status = error.response ? error.response.status : undefined;
      });

    return status;
  },

  async fetchAppliedAndUserProjects({ commit, dispatch }, user) {
    await ProjectService.getAppliedAndUserProjectsByUserId(user.id)

      .then(async (response) => {
        const projects = await Promise.all(
          response.map(async (project) => {
            const users = await getProjectUsers(project.id);
            project.users = users;
            return project;
          })
        );
        commit("SET_PROJECTS_OF_USER", projects);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          const notification = {
            type: "error",
            message:
              "Es gab ein Problem beim laden deiner Projekte: " + error.message,
          };
          dispatch("addNotification", notification);
        }
      });
  },

  setSelectedProject({ commit }, project) {
    commit("SET_SELECTED_PROJECT", project);
  },

  async createProject({ dispatch }, payload) {
    let status;

    await ProjectService.createProject(payload)
      .then((response) => {
        const notification = {
          type: "success",
          message: "Dein Projekt wurde erstellt!",
        };

        dispatch("addNotification", notification);
        status = response.status;
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "Es gab ein Problem beim erstellen deines Projektes: " +
            error.message,
        };

        dispatch("addNotification", notification);
        status = error.response ? error.response.status : undefined;
      });

    return status;
  },

  async initializeProject({ dispatch }, payload) {
    let status;
    await ProjectService.initializeProject(payload)
      .then((response) => {
        const notification = {
          type: "success",
          message: "Du hast das Projekt angenommen",
        };
        status = response.status;
        dispatch("addNotification", notification);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "Es gab ein Problem bei der Annahme deines Projektes: " +
            error.message,
        };
        status = error.response ? error.response.status : undefined;
        dispatch("addNotification", notification);
      });
    return status;
  },

  sortProjects({ commit, state }, { selectedOrderBy, orderByMap }) {
    if (state.projects) {
      let sortedList;
      if (selectedOrderBy.startsWith("Projektstart")) {
        sortedList = state.projects.sort(
          (a, b) =>
            Date.parse(a[orderByMap[selectedOrderBy]["orderBy"]]) -
            Date.parse(b[orderByMap[selectedOrderBy]["orderBy"]])
        );
      } else {
        sortedList = state.projects.sort(
          (a, b) =>
            a[orderByMap[selectedOrderBy]["orderBy"]] -
            b[orderByMap[selectedOrderBy]["orderBy"]]
        );
      }
      if (!orderByMap[selectedOrderBy]["Asc"]) {
        sortedList.reverse();
      }
      commit("SET_PROJECTS", sortedList);
    }
  },

  async closeProject({ dispatch }, projectId) {
    await ProjectService.closeProject(projectId).catch((error) => {
      const notification = {
        type: "error",
        message:
          "Es gab ein Problem bem Schliessen deines Projektes: " +
          error.message,
      };

      dispatch("addNotification", notification);
    });
  },

  clearProjects({ commit }) {
    commit("CLEAR_ALL_PROJECTS");
  },
};
async function getProjectUsers(projectId) {
  return await ProjectService.getProjectUsers(projectId);
}

async function getTagProjects(projectId) {
  return await TagProjectService.getTagProjects(projectId).then(
    (tagProjectsResponse) => {
      const tags = tagProjectsResponse.map((tagProject) => tagProject.tag);
      return tags;
    }
  );
}
export const getters = {
  getOpenProjects: (state) => {
    return state.projects.filter((project) => project.status === "Open");
  },
};
