<template>
  <v-container>
    <div class="text-h4">{{ name }}</div>
    <v-row>
      <v-col
        v-for="(tag, index) in tags"
        :key="index"
        cols="12"
        md="6"
        lg="4"
        xl="4"
      >
        <v-card
          color="tertiary40"
          min-height="150px"
          :class="{
            selected:
              topicHasSelectedTags(topicName) && tagIsAlreadySelected(tag),
          }"
          @click="selectTag(tag)"
        >
          <v-card-title>{{ tag.name }}</v-card-title>
          <v-card-subtitle>{{ tag.detail }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { translations } from "@/shared/translations";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Topic",
  props: ["topicData", "topicName"],

  data() {
    return {
      name: "",
      tags: null,
      allowMultipleSelect: true,
    };
  },

  computed: {
    ...mapGetters(["topicHasSelectedTags"]),
    ...mapState({ selectedTags: (state) => state.wizard.selectedTags }),
  },

  methods: {
    tagIsAlreadySelected(tag) {
      const found = this.selectedTags[this.topicName].find(
        (element) => JSON.stringify(element) === JSON.stringify(tag)
      );
      return found ? true : false;
    },

    selectTag(tag) {
      const params = { topicName: this.topicName, tag };

      if (!this.topicHasSelectedTags(this.topicName)) {
        this.addSelectedTag(params);
      } else if (this.tagIsAlreadySelected(tag)) {
        this.removeSelectedTag(params);
      } else if (this.allowMultipleSelect) {
        this.addSelectedTag(params);
      } else {
        this.replaceSelectedTag(params);
      }
    },

    formatTopicData() {
      this.allowMultipleSelect = this.topicData.allowMultipleSelect;
      this.tags = this.topicData.tags;
      this.name = translations(this.topicName);
    },

    ...mapActions([
      "addSelectedTag",
      "removeSelectedTag",
      "replaceSelectedTag",
    ]),
  },

  created() {
    this.formatTopicData();
  },
};
</script>

<style scoped>
.selected {
  outline: 5px black solid;
}
</style>
