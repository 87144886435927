<template>
  <v-card data-testId="applicationsOverview">
    <v-toolbar color="tertiary40" flat>
      <v-toolbar-title>Bewerbungen</v-toolbar-title>
    </v-toolbar>
    <v-card-subtitle v-if="!applications.length"
      >Keine Bewerbungen vorhanden
    </v-card-subtitle>
    <ShowApplications
      v-else
      v-for="(application, i) in applications"
      :key="i"
      :application="application"
      :userRole="userRole"
      :userId="userId"
    />
  </v-card>
</template>

<script>
import ShowApplications from "@/components/application/ShowApplications.vue";
export default {
  name: "ApplicationOverview",
  props: ["applications", "userRole", "userId"],
  components: { ShowApplications },
};
</script>
