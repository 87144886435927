<template>
  <v-alert dismissible :type="notification.type">
    {{ notification.message }}
  </v-alert>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions(["removeNotification"]),
  },

  mounted() {
    this.timeout = setTimeout(
      () => this.removeNotification(this.notification),
      10000
    );
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style scoped>
.notification-bar {
  margin: 1em 0 1em;
}
</style>
