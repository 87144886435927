import * as FileService from "@/services/FileService.js";

export const actions = {
  async addFile({ dispatch }, payload) {
    let notification;
    await FileService.uploadFile(payload)
      .then(() => {
        notification = {
          type: "success",
          message: "Deine Datei wurde hochgeladen!",
        };
        dispatch("addNotification", notification);
      })

      .catch((error) => {
        notification = {
          type: "error",
          message:
            "Es gab ein Problem beim hochladen der Datei: " + error.message,
        };
        dispatch("addNotification", notification);
      });
  },

  async fetchFiles({ dispatch }, payload) {
    await FileService.downloadFiles(payload)
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Projektdateien";
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const notification = {
            type: "info",
            message: "Auf diesem Projekt gibt es noch keine Dateien",
          };
          dispatch("addNotification", notification);
        } else {
          const notification = {
            type: "error",
            message:
              "Es gab ein Problem beim laden der Dateien: " + error.message,
          };
          dispatch("addNotification", notification);
        }
      });
  },
};
