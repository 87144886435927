<template>
  <v-card>
    <v-toolbar color="tertiary40" flat>
      <v-toolbar-title color="#FFF">Ausgewählte Optionen</v-toolbar-title>
    </v-toolbar>
    <v-list-item
      v-for="topic in Object.keys(projectTags)"
      :key="JSON.stringify(topic)"
    >
      <v-list-item-content>
        <v-list-item-title class="text-wrap">
          {{ translate(topic) }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content v-if="projectTags[topic]">
        <v-chip
          v-for="tag in projectTags[topic]"
          :key="JSON.stringify(tag)"
          color="tertiary40 lighten-1"
          class="d-flex justify-center"
        >
          {{ tag.name }}
        </v-chip>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { translations } from "@/shared/translations.js";
export default {
  name: "ShowTopicsAndTags",
  props: ["projectTags"],
  methods: {
    translate(word) {
      return translations(word);
    },
  },
};
</script>

<style></style>
