import * as WizardService from "@/services/WizardService";
import { translations } from "@/shared/translations";
import Vue from "vue";
export const state = {
  types: [],
  generalInfoType: null,
  selectedType: null,
  selectedTags: {},
};

export const mutations = {
  SET_TYPES(state, types) {
    state.types = types;
  },

  SET_GENERAL_TYPE(state, type) {
    state.generalInfoType = type;
  },

  SET_SELECTED_TYPE(state, index) {
    state.selectedType = index;
  },

  ADD_SELECTED_TAG(state, selectedTag) {
    const { topicName, tag } = selectedTag;
    if (state.selectedTags[topicName] === undefined) {
      Vue.set(state.selectedTags, topicName, [tag]);
    } else {
      state.selectedTags[topicName].push(tag);
    }
  },

  REMOVE_SELECTED_TAG(state, selectedTag) {
    const { topicName, tag } = selectedTag;
    const index = state.selectedTags[topicName].findIndex(
      (element) => JSON.stringify(element) === JSON.stringify(tag)
    );
    state.selectedTags[topicName].splice(index, 1);
  },

  REPLACE_SELECTED_TAG(state, selectedTag) {
    const { topicName, tag } = selectedTag;
    state.selectedTags[topicName] = [tag];
  },

  CLEAR_SELECTED_TAGS(state) {
    state.selectedTags = {};
  },

  CLEAR_SELECTED_TYPE(state) {
    state.selectedType = null;
  },
};

export const actions = {
  async fetchTypes({ commit, dispatch }) {
    await WizardService.getTypes()
      .then((response) => {
        let tempTypes = [];

        response.data.map((type) => {
          if (!type.isGeneralInfo) {
            //need to translate type for v-select display in Project.vue
            const translated = translations(type.name);
            type.translated = translated;
            tempTypes.push(type);
          } else {
            //there is only one generalInfoType. Therefore, only one assignment is needed
            commit("SET_GENERAL_TYPE", type);
          }
        });
        commit("SET_TYPES", tempTypes);
      })

      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "Informationen konnten nicht geladen werden: " + error.message,
        };
        dispatch("addNotification", notification);
      });
  },

  setSelectedType({ rootState, commit }, routeParam) {
    const index = rootState.wizard.types
      .map(function (type) {
        return type.name;
      })
      .indexOf(routeParam);

    commit("SET_SELECTED_TYPE", rootState.wizard.types[index]);
  },

  addSelectedTag({ commit }, tag) {
    commit("ADD_SELECTED_TAG", tag);
  },

  removeSelectedTag({ commit }, tag) {
    commit("REMOVE_SELECTED_TAG", tag);
  },

  replaceSelectedTag({ commit }, tag) {
    commit("REPLACE_SELECTED_TAG", tag);
  },

  clearSelectedTags({ commit }) {
    commit("CLEAR_SELECTED_TAGS");
  },

  clearSelectedType({ commit }) {
    commit("CLEAR_SELECTED_TYPE");
  },
};

export const getters = {
  topicHasSelectedTags: (state) => (key) => {
    if (state.selectedTags[key] === undefined) {
      return false;
    }
    if (state.selectedTags[key].length === 0) {
      return false;
    }
    return true;
  },

  getGeneralInfoTypeTags: (state) => (topicName) => {
    if (state.generalInfoType) {
      return state.generalInfoType.topics.filter(
        (topic) => topic.name === topicName
      )[0].tags;
    } else return [];
  },

  getTotalTopicsForWizard: (state) => {
    if (state.selectedType && state.selectedType.topics.length) {
      return state.selectedType.topics.concat(state.generalInfoType.topics);
    } else {
      return state.generalInfoType.topics;
    }
  },

  getTotalTopicsForProjectDetail: (state) => {
    if (state.types.length) {
      const typeTopics = [].concat(...state.types.map((type) => type.topics));
      return typeTopics.concat(state.generalInfoType.topics);
    } else {
      return state.generalInfoType.topics;
    }
  },

  getTypeName: (state) => (id) => {
    const type = state.types.filter((type) => type.id === id)[0];
    return type ? type.translated : "";
  },
};
